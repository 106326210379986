import React, { useRef, useEffect, useState } from 'react'

import { get } from 'src/lib/api'

import styles from './index.module.scss'


const Information = ({ gym }) => {

    const ul = useRef()

    const [ inner, setInner ] = useState(null)

    useEffect(() => {

        function resize() {
            ul.current.style.width = '99999px'
            ul.current.style.flexWrap = 'wrap'
            const w = Array.from(ul.current.getElementsByTagName('li')).reduce((w, li) => w + li.clientWidth, 0)
            ul.current.style.width = w + 'px'
            ul.current.style.flexWrap = ''
            ul.current.style.animationDuration = w * 0.007 + 's';
        }

        if (typeof window !== `undefined`) {
            get(`/app/data/gyms/count.json`, { t: Date.now() }, false).then((data) => {
                if (gym) {
                    setInner((
                        <React.Fragment>
                            <li className={styles.textJa}>{`${data[gym].name_ja}: 只今の人数は${data[gym].count}名です。${data[[gym]].text}`}</li>
                            <li className={styles.textEn}>{`${data[gym].name_en}: We have ${data[gym].count} people now.`}</li>
                            <li className={styles.textJa}>{`${data[gym].name_ja}: 只今の人数は${data[gym].count}名です。${data[[gym]].text}`}</li>
                            <li className={styles.textEn}>{`${data[gym].name_en}: We have ${data[gym].count} people now.`}</li>
                        </React.Fragment>
                    ))
                } else {
                    setInner((
                        <React.Fragment>
                            <li className={styles.textJa}>{`${data.toritsudai.name_ja}: 只今の人数は${data.toritsudai.count}名です。${data.toritsudai.text}`}</li>
                            <li className={styles.textEn}>{`${data.toritsudai.name_en}: We have ${data.toritsudai.count} people now.`}</li>
                            <li className={styles.textJa}>{`${data.ekoda.name_ja}: 只今の人数は${data.ekoda.count}名です。${data.ekoda.text}`}</li>
                            <li className={styles.textEn}>{`${data.ekoda.name_en}: We have ${data.ekoda.count} people now.`}</li>
                        </React.Fragment>
                    ))
                }
                resize()

                window.addEventListener('resize', resize)
            })
        }

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [gym])


    return (
        <div className={styles.information}>
            <ul ref={ul}>
                {[0, 1, 2].map((i) => <React.Fragment key={i}>{inner}</React.Fragment>)}
            </ul>
        </div>
    )
}

export default Information