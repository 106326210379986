import React, { useRef, useState } from 'react'

import Slider from 'react-slick'
import Inner from 'src/components/inner/index'
import BackSlide from './backslide'

import styles from './index.module.scss'


const Features = ({ items }) => {

    const slide = useRef()

    const [index, setIndex] = useState(0)

    const settings = {
        speed: 750,
        cssEase: `cubic-bezier(0.77, 0, 0.175, 1)`,
        dots: false,
        arrows: false,
        infinite: false,
        draggable: false,
        accessibility: false,
        beforeChange: (oldIndex, newIndex) => {
            setIndex(newIndex)
        }
    }


    return (
        <div className={styles.features}>
            <BackSlide index={index} items={items} />

            <Inner className={styles.inner}>
                <div className={styles.box}>
                    <Slider ref={slide} {...settings}>
                        {items.map((item, i) => (
                            <div key={i} className={styles.boxItem}>
                                <div className={styles.number}>{`FEATURE 0${i + 1}`}</div>
                                <h3 className={styles.title}>{item.title}</h3>
                                <div className={styles.text}>
                                    <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    <div className={styles.bottom}>
                        <ol className={styles.dots}>
                            {items.map((item, i) => (
                                <li key={i}>
                                    <button onClick={() => slide.current.slickGoTo(i)} aria-label={`FEATURE 0${i + 1}`} aria-current={i === index}></button>
                                </li>
                            ))}
                        </ol>
                        <div className={styles.arrows}>
                            <button className={styles.prev} onClick={() => slide.current.slickPrev()} aria-hidden={index === 0}>prev</button>
                            <button className={styles.next} onClick={() => slide.current.slickNext()} aria-hidden={index === items.length - 1}>next</button>
                        </div>
                    </div>
                </div>
            </Inner>
        </div>
    )
}

export default Features