import React from 'react'

import Link from 'src/components/link/index'
import Image from 'src/components/image/index'

import styles from './index.module.scss'


const I_AM_THE_FIT = () => {

    return (
        <Link to="/iatf/" className={styles.iatf}>
            <div className={styles.placer}>
                <div className={styles.imageUpper}>
                    <Image src="/iatf/upper.jpg" minWidth={880} />
                </div>
                <div className={styles.imageLower}>
                    <Image src="/iatf/lower.jpg" minWidth={880} />
                </div>
            </div>
            <div className={styles.data}>
                {/* <div className={styles.qrCode}>
                    <img src="/img/iatf/qrcode.png" alt="QRコード" />
                </div> */}
                <div className={styles.text}>
                    <div className={styles.title}>I AM THE FIT</div>
                    <div className={styles.reserve}>パーソナルトレーニング</div>
                </div>
            </div>
        </Link>
    )
}

export default I_AM_THE_FIT