import React from 'react'

import { usePrevious } from 'src/hooks/use_previous'

import Image from 'src/components/image/index'

import styles from './backslide.module.scss'


const Features = ({ items, index }) => {

    const prevIndex = usePrevious(index)

    return (
        <div className={styles.backslide}>
            <ul>
                {items.map((item, i) => {
                    let zIndex = 0

                    if (i === index) {
                        zIndex = 2
                    } else if (i === prevIndex) {
                        zIndex = 1
                    }

                    return (
                        <li key={i} aria-current={index === i} style={{ zIndex: zIndex }}>
                            <Image src={item.image} minWidth={880} />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Features